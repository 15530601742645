
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Genrator from './pages/generator/Genrator';
import  { Toaster } from 'react-hot-toast';
import Header from './components/header/Header';
import Home from './pages/Home';
import Validator from './pages/validator/Validator';

function App() {
  return (
    <div className="App">
    <Toaster/>
    <Header/>
    <BrowserRouter>
    <Routes>
      <Route path='/generate' element={<Genrator/>}/>
      <Route path='/validate' element={<Validator/>}/>
      <Route path='/' element={<Home/>}/>
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
